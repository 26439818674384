import {
  Container,
  Heading,
  StackDivider,
  Input,
  VStack,
  HStack,
  AspectRatio,
  Text,
  Box,
  Button,
  Code,
  FormControl,
  FormLabel,
  Select,
  Divider,
  useToast,
  Center,
  Skeleton,
  Stack,
} from '@chakra-ui/react';
import axios from 'axios';
import React, { useState } from 'react';
import appInfo from '../../package.json';
import DateTimeRangePicker from '@wojtekmaj/react-datetimerange-picker';
import ReactPlayer from 'react-player';
import { CopyToClipboard } from 'react-copy-to-clipboard';

function TestYTStream() {
  const toast = useToast();
  const [channel, setChannel] = useState('');
  const [jpServerSel, setJpServerSel] = useState('');
  const [jpChannelSel, setJpChannelSel] = useState('');
  const [jpRange, setJpRange] = useState([
    new Date(Date.now() - 3600 * 1000),
    new Date(),
  ]);
  const [finalCode, setFinalCode] = useState('');

  const [showResult, setShowResult] = useState(false);
  const [loadingJp, setloadingJp] = useState(false);
  const [showJpResult, setShowJpResult] = useState(false);
  const [codeCopied, setCodeCopied] = useState(false);

  const jpServers = [
    {
      name: 'Thai 10',
      url: 'https://timeshiftth10.jpnettv.live:7700/playindex.m3u8',
    },
    {
      name: 'USA',
      url: 'https://t-s-u186.cloudrsst.com:7700/playindex.m3u8',
    },
  ];

  const jpChannels = [
    {
      name: 'ＮＨＫ総合・東京',
      code: 'kanto_nhk_g_540',
    },
    {
      name: 'ＮＨＫＥテレ・東京',
      code: 'kanto_nhk_edu_540',
    },
    {
      name: '日テレ',
      code: 'kanto_nippon_540',
    },
    {
      name: 'ＴＢＳ',
      code: 'kanto_tbs_540',
    },
    {
      name: 'フジテレビ',
      code: 'kanto_fuji_540',
    },
    {
      name: 'テレビ朝日',
      code: 'kanto_tv_asahi_540',
    },
    {
      name: 'テレビ東京',
      code: 'kanto_tv_tokyo_540',
    },
    {
      name: 'TOKYO MX',
      code: 'kanto_tokyo_mx1_540',
    },
    {
      name: 'NHK総合大阪',
      code: 'kansi_nhk_g_720',
    },
    {
      name: '読売テレビ',
      code: 'kansi_yomiuri_540',
    },
    {
      name: 'ＭＢＳ毎日放送',
      code: 'kansi_mbs_540',
    },
    {
      name: 'ＡＢＣテレビ',
      code: 'kansi_abc_asahi_540',
    },
    {
      name: '関西 テレビ',
      code: 'kansi_tv_540',
    },
    {
      name: 'テレビ大阪',
      code: 'kansi_tv_osaka_540',
    },
    {
      name: 'ＮＨＫ ＢＳ１',
      code: 'nhkbs1_540',
    },
    {
      name: '時代劇専門チャンネル',
      code: 'jidaigeki',
    },
    {
      name: 'サンテレビ HD',
      code: 'kansi_sun_tv_720',
    },
    {
      name: 'Green Channel',
      code: 'green',
    },
    {
      name: 'NHKBSP',
      code: 'bspremium_540',
    },
    {
      name: 'BS日テレ',
      code: 'bsnihontv_720',
    },
    {
      name: 'BS朝日',
      code: 'bsasahi_720',
    },
    {
      name: 'BS‐TBS',
      code: 'bstbs_720',
    },
    {
      name: 'BSテレ東',
      code: 'bsjapan_720',
    },
    {
      name: 'BSフジ',
      code: 'bsfuji_720',
    },
    {
      name: 'スターチャンネル1 JP',
      code: 'starch1_540',
    },
    {
      name: 'スターチャンネル2 JP',
      code: 'starch2_540',
    },
    {
      name: 'スターチャンネル3 JP',
      code: 'starch3_540',
    },
    {
      name: 'BSアニマックス',
      code: 'janimax_540',
    },
    {
      name: 'FOX',
      code: 'jfox',
    },
    {
      name: 'BS釣りビジョン',
      code: 'Fishing_broadcasting',
    },
    {
      name: 'シネフィルWOWOW',
      code: 'cinefil_wowow_540',
    },
    {
      name: '日本映画専門チャンネル JP',
      code: 'igasennmonn_540',
    },
    {
      name: 'ディズニーチャンネル',
      code: 'disney_channel',
    },
    {
      name: '東映チャンネル',
      code: 'TOA',
    },
    {
      name: '衛星劇場',
      code: 'gekijjyou',
    },
    {
      name: '映画 チャンネルNECO JP',
      code: 'Neco',
    },
    {
      name: 'ムービープラス JP',
      code: 'movieplus',
    },
    {
      name: 'スカイA',
      code: 'Sky_A',
    },
    {
      name: 'GAORA SPORTS',
      code: 'GaoRa',
    },
    {
      name: '日テレジータス',
      code: 'japan_tv_G',
    },
    {
      name: 'ファミリー劇場',
      code: 'familytheater',
    },
    {
      name: 'MTV',
      code: 'mtv',
    },
    {
      name: 'キッズステーション',
      code: 'jkids',
    },
    {
      name: 'カートゥーンネットワーク',
      code: 'cartoon_network',
    },
    {
      name: 'ディズニージュニア',
      code: 'disney_junior',
    },
    {
      name: 'Discovery Channel',
      code: 'jdiscovery',
    },
    {
      name: 'アニマルプレネット',
      code: 'Animal',
    },
    {
      name: 'ナショナルジオグラフィック',
      code: 'National_geographic',
    },
  ];

  const handleJpServerChange = e => {
    setCodeCopied(false);
    setShowJpResult(false);
    setJpServerSel(e.target.value);
  };
  const handleJpChannelChange = e => {
    setCodeCopied(false);
    setShowJpResult(false);
    setJpChannelSel(e.target.value);
  };

  const handleJpSubmit = e => {
    if (jpServerSel && jpChannelSel) {
      setloadingJp(true);
      let startTime = Math.floor(jpRange[0] / 1000);
      let endTime = Math.floor(jpRange[1] / 1000);
      setFinalCode(
        `${jpServerSel}?call=playback&channel_code=${jpChannelSel}&start_time=${startTime}&end_time=${endTime}s&is_ssl=1`
      );

      axios
        .get(finalCode)
        .then(res => {
          if (res.status === 200) {
            setloadingJp(false);
            setShowJpResult(true);
          }
        })
        .catch(error => {
          if (error.response.status >= 400) {
            toast({
              title: 'Error',
              description: `Error code: ${error.response.status}`,
              status: 'error',
              duration: 3000,
              isClosable: true,
            });
            setloadingJp(false);
            showJpResult(false);
          }
        });
    }
  };

  const handleChange = e => {
    setShowResult(false);
    setChannel(e.target.value);
  };

  const handleSubmit = () => {
    if (channel !== '') {
      setShowResult(true);
    }
  };

  return (
    <div className="TestYTStream">
      <Container
        maxW="100%"
        h="100%"
        padding="10px"
        textAlign="center"
        background="whiteAlpha.300"
      >
        <VStack divider={<StackDivider />} spacing={4} align="stretch">
          <Box>
            <Heading>DubDub watchTV Tools</Heading>
          </Box>
          <Box borderWidth="1px" p={3}>
            <Text align="left" paddingLeft={5}>
              DubDub watchTV Version:{' '}
              <Code
                colorScheme="yellow"
                children={appInfo.version}
                paddingLeft={3}
                paddingRight={3}
              />
            </Text>
          </Box>
          <Box boxShadow="1px" borderWidth="1px" p={4}>
            <FormControl>
              <HStack spacing={2} display="flex">
                <VStack spacing={2} flex={1}>
                  <FormLabel htmlFor="jpserver">Server</FormLabel>
                  <Select
                    id="jpserver"
                    placeholder="Select Server"
                    onChange={handleJpServerChange}
                  >
                    {jpServers.map(server => (
                      <option value={server.url}>{server.name}</option>
                    ))}
                  </Select>
                </VStack>
                <VStack spacing={2} flex={1}>
                  <FormLabel htmlFor="jpchannel">Channel</FormLabel>
                  <Select
                    id="jpchannel"
                    placeholder="Select Channel"
                    onChange={handleJpChannelChange}
                  >
                    {jpChannels.map(channel => (
                      <option value={channel.code}>{channel.name}</option>
                    ))}
                  </Select>
                </VStack>
              </HStack>
              <HStack spacing={2} display="flex">
                <VStack spacing={1} flex={2}>
                  <FormLabel htmlFor="jpTime">
                    Time Range in Your Time Zone
                  </FormLabel>
                  <DateTimeRangePicker
                    onChange={e => {
                      setShowJpResult(false);
                      setJpRange(e);
                    }}
                    value={jpRange}
                    closeWidgets="false"
                    locale="ja"
                    maxDetail="second"
                    minDate={new Date(Date.now() - 14 * 24 * 60 * 60 * 1000)}
                    maxDate={new Date(Date.now())}
                  />
                </VStack>
                <VStack spacing={1} flex={1}>
                  <Button colorScheme="teal" size="sm" onClick={handleJpSubmit}>
                    Submit
                  </Button>
                </VStack>
              </HStack>
            </FormControl>
            <Divider />
            {loadingJp ? (
              <Box paddingTop={10} align="center" maxW="100%">
                <Stack>
                  <Skeleton height="20px" />
                  <Skeleton height="20px" />
                  <Skeleton height="20px" />
                </Stack>
              </Box>
            ) : showJpResult ? (
              <Box paddingTop={10} align="center">
                <AspectRatio ratio={16 / 9} maxH={480} w="80%">
                  <ReactPlayer
                    url={finalCode}
                    volume={1}
                    width="100%"
                    height="100%"
                    config={{
                      file: {
                        attributes: {
                          controls: true,
                          pip: true,
                          playsInline: true,
                        },
                      },
                      youtube: {
                        playerVars: {
                          rel: 0,
                          controls: 0,
                          autoPlay: 1,
                          mute: 0,
                          disablekb: 1,
                          playsinline: 1,
                        },
                      },
                    }}
                  />
                </AspectRatio>
                <HStack>
                  <Center maxW="100%">
                    <CopyToClipboard
                      text={`ffmpeg -i '${finalCode}' -c copy ${jpChannelSel}.mp4`}
                      onCopy={() => {
                        toast({
                          title: 'Code Copied',
                          description: 'Please copy to ffmpeg in Terminal App.',
                          status: 'success',
                          duration: 3000,
                          isClosable: true,
                        });
                        setCodeCopied(true);
                      }}
                    >
                      <Code align="left" m={10} w="60%">
                        ffmpeg -i '{finalCode}' -c copy {jpChannelSel}.mp4{' '}
                      </Code>
                    </CopyToClipboard>
                    <CopyToClipboard
                      text={`ffmpeg -i '${finalCode}' -c copy ${jpChannelSel}.mp4`}
                      onCopy={() => {
                        toast({
                          title: 'Code Copied',
                          description: 'Please copy to ffmpeg in Terminal App.',
                          status: 'success',
                          duration: 3000,
                          isClosable: true,
                        });
                        setCodeCopied(true);
                      }}
                    >
                      <Button colorScheme="orange" size="md">
                        Copy Code
                      </Button>
                    </CopyToClipboard>
                  </Center>
                </HStack>
              </Box>
            ) : null}
          </Box>
          <Box borderWidth="1px" p={10}>
            <HStack spacing={3} display="flex">
              <FormLabel>Youtube Channel</FormLabel>
              <Input
                value={channel}
                onChange={handleChange}
                placeholder="Paste code after 'https://www.youtube.com/channel/'"
              />
              <Button colorScheme="teal" size="sm" onClick={handleSubmit}>
                Submit
              </Button>
            </HStack>
          </Box>
          <Box>
            {showResult ? (
              <AspectRatio ratio={16 / 9} maxH="500px">
                <iframe
                  title="youtube"
                  src={`https://www.youtube.com/embed/live_stream?channel=${channel}&disablekb=1&mute=1&fs=0&modestbranding=1&rel=0&controls=1`}
                  frameborder="0"
                />
              </AspectRatio>
            ) : null}
          </Box>
        </VStack>
      </Container>
    </div>
  );
}

export default TestYTStream;
