import React, { useEffect, ReactNode } from 'react';
import {
  useControllableState,
  Box,
  Text,
  Grid,
  GridItem,
  AspectRatio,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Button,
  Image,
  VStack,
  HStack,
  StackDivider,
  Container,
  Wrap,
  Alert,
  AlertIcon,
  Badge,
} from '@chakra-ui/react';

import { FiChevronDown } from 'react-icons/fi';

import LiveChannels from '../dictionary/LiveChannels';
import CommonDict from '../dictionary/Common';
import { CheckDynamicStream } from '../components/CheckDynamicStream';

import ReactPlayer from 'react-player';
import ReactGA from 'react-ga4';
import { app } from '../components/firebase';

import axios from 'axios';

function WatchTV() {
  ReactGA.initialize(app.options.measurementId);

  const lang = 'tc';

  const [chooseChannel, setChooseChannel] = useControllableState({
    defaultValue: 'WW-JP',
  });
  const [chooseStream, setChooseStream] = useControllableState({
    defaultValue: 0,
  });

  const [streamLink, setStreamLink] = useControllableState({
    defaultValue: '',
  });

  const [muted, setMuted] = useControllableState({
    defaultValue: true,
  });

  useEffect(() => {
    if (LiveChannels.tvChannels[chooseChannel].dynamicLink) {
      CheckDynamicStream(chooseChannel).then(res => {
        console.log('temp', res);
        setStreamLink(res);
      });
    } else {
      setStreamLink(
        LiveChannels.tvChannels[chooseChannel].streamLink[chooseStream]
      );
    }
    document.title =
      'DubDub watchTV | ' +
      LiveChannels.tvChannels[chooseChannel].channel_tcName +
      ' - ' +
      LiveChannels.tvChannels[chooseChannel].channel_enName;
    ReactGA.event({
      category: 'page_title',
      action: LiveChannels.tvChannels[chooseChannel].channel_tcName,
      label: LiveChannels.tvChannels[chooseChannel].channel_enName, // optional
      value: 1, // optional, must be a number
      nonInteraction: true, // optional, true/false
      transport: 'xhr', // optional, beacon/xhr/image
    });
  }, [chooseChannel]);

  // Video Player
  function VideoPlayer(props) {
    console.log(streamLink);
    return (
      <Box as="span" p={5}>
        <AspectRatio ratio={16 / 9} maxH="80vh">
          <ReactPlayer
            url={streamLink}
            volume={1}
            width="100%"
            height="100%"
            config={{
              file: {
                attributes: {
                  crossorigin: 'anonymous',
                  controls: true,
                  pip: true,
                  playsInline: true,
                },
              },
              youtube: {
                playerVars: {
                  rel: 0,
                  controls: 0,
                  autoPlay: 1,
                  mute: 0,
                  disablekb: 1,
                  playsinline: 1,
                },
              },
            }}
          />
        </AspectRatio>
        <Box
          p={1}
          maxH="max-content"
          bgGradient="linear(to-l, red.100, yellow.100)"
        >
          <Text
            bgGradient="linear(to-b, #7928CA, #FF0080)"
            bgClip="text"
            fontSize="xl"
            fontWeight="extrabold"
          >
            {CommonDict.common[lang].nowPlaying + ': '}
            {LiveChannels.tvChannels[chooseChannel]['channel_' + lang + 'Name']}
          </Text>
        </Box>
        <Box>
          {/* <Alert status="warning" variant="top-accent">
            <AlertIcon />
            Data uploaded to the server. Fire on!
          </Alert> */}
        </Box>
      </Box>
    );
  }

  // Channel List
  function ChannelList(props) {
    return (
      <Menu>
        <MenuButton as={Button} rightIcon={<FiChevronDown />}>
          {CommonDict.common[lang].allChannel}
        </MenuButton>
        <MenuList maxH="50vh" sx={{ overflow: 'scroll' }}>
          {Object.entries(LiveChannels.tvChannels).map(
            ([channelID, channelDetails]) => (
              <MenuItem
                display="flex"
                onClick={() => setChooseChannel(channelID)}
              >
                <Image
                  maxW="40px"
                  maxH="40px"
                  bg="white"
                  src={channelDetails.channel_icon}
                  mr="12px"
                />

                <Text fontSize="sm">{channelDetails.channel_tcName}</Text>
              </MenuItem>
            )
          )}
        </MenuList>
      </Menu>
    );
  }

  // Channel Filter by Region
  function ChannelFilterRegion({ region }) {
    return (
      <Menu>
        <MenuButton as={Button} rightIcon={<FiChevronDown />}>
          {CommonDict.common[lang][region + 'Channel']}
        </MenuButton>
        <MenuList maxH="50vh" sx={{ overflow: 'scroll' }}>
          {Object.entries(LiveChannels.tvChannels).map(
            ([channelID, channelDetails]) =>
              channelDetails.region === region ? (
                <MenuItem
                  display="flex"
                  onClick={() => setChooseChannel(channelID)}
                >
                  <Image
                    maxW="40px"
                    maxH="40px"
                    bg="white"
                    src={channelDetails.channel_icon}
                    mr="12px"
                  />

                  <Text fontSize="sm">{channelDetails.channel_tcName}</Text>
                </MenuItem>
              ) : null
          )}
        </MenuList>
      </Menu>
    );
  }

  // Channel Filter by Type
  function ChannelFilterType({ type }) {
    return (
      <Menu>
        <MenuButton as={Button} rightIcon={<FiChevronDown />}>
          {CommonDict.common[lang][type + 'Channel']}
        </MenuButton>
        <MenuList maxH="50vh" sx={{ overflow: 'scroll' }}>
          {Object.entries(LiveChannels.tvChannels).map(
            ([channelID, channelDetails]) =>
              channelDetails.type === type ? (
                <MenuItem
                  display="flex"
                  onClick={() => setChooseChannel(channelID)}
                >
                  <Image
                    maxW="40px"
                    maxH="40px"
                    bg="white"
                    src={channelDetails.channel_icon}
                    mr="12px"
                  />

                  <Text fontSize="sm">{channelDetails.channel_tcName}</Text>
                </MenuItem>
              ) : null
          )}
        </MenuList>
      </Menu>
    );
  }

  return (
    <div className="watchTV">
      <VStack spacing={0}>
        <Wrap
          spacing={2}
          w="100%"
          alignItems="center"
          bg="gray.700"
          p={4}
          boxShadow="0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)"
          variant="ghost"
        >
          <ChannelList />
          <ChannelFilterRegion region="hk" />
          <ChannelFilterRegion region="jp" />
          <ChannelFilterRegion region="mo" />
          <ChannelFilterRegion region="tw" />
          <ChannelFilterType type="news" />
          <ChannelFilterType type="sports" />
          <ChannelFilterType type="kids" />
        </Wrap>
        <Container maxW="100vw" bgGradient="linear(to-b, gray.700, gray.500)">
          <VideoPlayer />
        </Container>
      </VStack>
    </div>
  );
}

export default WatchTV;
