import React from 'react';
import { Box, Text, IconButton, Link } from '@chakra-ui/react';
import { ColorModeSwitcher } from '../components/ColorModeSwitcher';
import { BiTv } from 'react-icons/bi';
import { FaHome } from 'react-icons/fa';
import { GiJapan } from 'react-icons/gi';
import { RiLayoutGridFill } from 'react-icons/ri';

//RiEnglishInput english icon
//RiTranslate

function Header() {
  return (
    <div className="header">
      <Box
        bg="gray.800"
        boxShadow="0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)"
      >
        <Box px={{ md: '2rem' }}>
          <Box
            as="nav"
            position="relative"
            display="flex"
            flexWrap="wrap"
            justifyContent="space-between"
            alignItems="center"
            p=".75rem"
          >
            <Box flex={3}>
              <Box display="flex" my={3} pr={2}>
                <Box minWidth="46px" maxWidth="0px">
                  <BiTv fontSize="46px" color="white" />
                </Box>
                <Box>
                  <Text
                    color="gray.200"
                    pl=".6rem"
                    fontWeight={800}
                    fontSize="lg"
                    lineHeight=".8rem"
                    letterSpacing="widest"
                  >
                    DubDub
                  </Text>
                  <Text
                    color="white"
                    pl={2}
                    fontWeight={500}
                    fontSize="2xl"
                    lineHeight="2.3rem"
                    letterSpacing=".5px"
                  >
                    watchTV
                  </Text>
                </Box>
              </Box>
            </Box>
            <Link href="./">
              <IconButton
                size="md"
                fontSize="lg"
                variant="ghost"
                color="white"
                marginLeft="2"
                aria-label="Home"
                icon={<FaHome />}
              />
            </Link>
            <Link href="multi">
              <IconButton
                size="md"
                fontSize="lg"
                variant="ghost"
                color="white"
                marginLeft="2"
                aria-label="Multi Channels"
                icon={<RiLayoutGridFill />}
              />
            </Link>
            <Link href="japanLives">
              <IconButton
                size="md"
                fontSize="lg"
                variant="ghost"
                color="white"
                marginLeft="2"
                aria-label="Multi Channels"
                icon={<GiJapan />}
                
              />
            </Link>
            <ColorModeSwitcher justifySelf="flex-end" />
          </Box>
        </Box>
      </Box>
    </div>
  );
}

export default Header;
