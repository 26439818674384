import React from 'react';
import { ChakraProvider, theme, Container } from '@chakra-ui/react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Headroom from 'react-headroom';
import Header from './pages/Header';

import './css/App.css';
import WatchTV from './pages/WatchTV';
import Footer from './pages/Footer';
import JapanYT from './pages/JapanYT';

import { app } from './components/firebase.js';
import { getAnalytics } from 'firebase/analytics';
import Multiviewer from './pages/Multiviewer';
import TestYTStream from './pages/TestYTStream';

function App() {
  // getAnalytics(app);
  return (
    <ChakraProvider theme={theme}>
      <Container maxW="-webkit-fill-available">
        {/* <Headroom> */}
        <Header maxH="20vh" />
        {/* </Headroom> */}
        <BrowserRouter>
          <Routes>
            <Route path="/multi" element={<Multiviewer />} />
            <Route path="/japanLives" element={<JapanYT />} />
            <Route path="/yt" element={<TestYTStream />} />
            <Route path="/" element={<WatchTV />} />
            <Route path="*" element={<WatchTV />} />
          </Routes>
        </BrowserRouter>
        <Footer />
      </Container>
    </ChakraProvider>
  );
}

export default App;
