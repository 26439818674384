import axios from 'axios';
import LiveChannels from '../dictionary/LiveChannels';

export function CheckDynamicStream(channel, mode) {
  const corsApi = 'https://cors.lwp.workers.dev/?';
  const viu = 'https://api.viu.now.com/p8/3/getLiveURL';
  const checkNHKalive = 'http://www3.nhk.or.jp/news/json16/tvnews.json';
  const getNHKLiveLink =
    'https://www3.nhk.or.jp/news/live/movie/player_live.json';

  switch (channel) {
    case 'now331':
    case 'now332':
    case 'now333':
      return axios
        .get(
          corsApi +
            `https://d1jithvltpp1l1.cloudfront.net/getLiveURL?channelno=${channel.replace(
              /^\D+/g,
              ''
            )}&format=HLS`,
          {
            mode: 'no-cors',
            withCredentials: false,
            credentials: 'same-origin',
            crossdomain: true,
          }
        )
        .then(res => {
          return res.data.asset.hls.adaptive[0];
        });
      break;

    case 'now096':
    case 'now099':
      axios
        .post(corsApi + 'https://api.viu.now.com/p8/3/getLiveURL', {
          contentId: channel.replace(/^\D+/g, ''),
          deviceId: '0000anonymous_user',
          deviceType: 'IOS_PHONE',
        })
        .then(res => {
          return res.data.asset[0];
        });

    case 'now630':
      return axios
        .get(
          corsApi +
            `https://d1jithvltpp1l1.cloudfront.net/getLiveURL?channelno=${channel.replace(
              /^\D+/g,
              ''
            )}&format=HLS`,
          {
            mode: 'no-cors',
            withCredentials: false,
            credentials: 'same-origin',
            crossdomain: true,
          }
        )
        .then(res => {
          return res.data.asset;
        });

    case 'OC1':
    case 'OC2':
    case 'OC3':
      return axios
        .get(
          corsApi +
            `https://olympics.com/tokenGenerator?url=https://ott-dai-oc.akamaized.net/${channel.toUpperCase()}/master.m3u8&domain=${
              window.location.hostname
            }`,
          {
            mode: 'no-cors',
            withCredentials: false,
            credentials: 'same-origin',
            crossdomain: true,
          }
        )
        .then(res => {
          return res.data;
        });

    case 'NHKG2':
      if (mode === 1) {
        return axios.get(corsApi + checkNHKalive).then(res => {
          if (res.data.viewFlg === true) {
            return axios.get(getNHKLiveLink).then(res => {
              return res.data.mediaResource.url;
            });
          } else {
            return LiveChannels.tvChannels['GunmaTV-jp'].streamLink[0];
          }
        });
      }

      return axios.get(getNHKLiveLink).then(res => {
        return res.data.mediaResource.url;
      });

    default:
      return LiveChannels.tvChannels[channel].streamLink[0];
  }
}

