import {
  Box,
  AspectRatio,
  SimpleGrid,
  useControllableState,
  Wrap,
  Button,
  Spacer,
  IconButton,
} from '@chakra-ui/react';
import React, { useEffect } from 'react';
import GridLayout from 'react-grid-layout';
import ReactPlayer from 'react-player';
import { CheckDynamicStream } from '../components/CheckDynamicStream';
import { RiLayoutGridFill, RiPlayMiniFill, RiPauseFill } from 'react-icons/ri';
import LiveChannels from '../dictionary/LiveChannels';

function Multiviewer() {
  const jpChannels = ['NTV-News24', 'WW-JP', 'NHK-World', 'TBS-News-DIG'];
  //const jpChannels = ['NTV-News24', 'WW-JP', 'NHK-World', 'NHKG2'];
  const worldChannels = ['Al-Jazeera-Eng', 'CNN', 'ABC-News', 'SkyNewsUK'];
  const hkChannels = ['icable-news', 'cable78', 'rthk32', 'tvb83'];
  const twChannels = ['SET-TW', 'CTITV-TW', 'EBC-TW', 'FTVN-TW'];

  const [channelSet, setChannelSet] = useControllableState({
    defaultValue: jpChannels,
  });
  const [vPlaying, setVPlaying] = useControllableState({
    defaultValue: true,
  });

  useEffect(() => {
    document.title = 'DubDub watchTV | 四格睇電視 - watchTV in Grid View';
    const timer = setTimeout(() => {
      let hero = document.getElementById('videoPlayer');
      hero && hero.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }, 2000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="multiviewer">
      <Wrap
        spacing={2}
        w="100%"
        alignItems="center"
        bg="blackAlpha.300"
        p={4}
        boxShadow="0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)"
        variant="ghost"
      >
        <Button
          leftIcon={<RiLayoutGridFill />}
          colorScheme="blue"
          variant="outline"
          size="sm"
          onClick={e => {
            setChannelSet(worldChannels);
            let hero = document.getElementById('videoPlayer');
            e.preventDefault();
            hero && hero.scrollIntoView({ behavior: 'smooth', block: 'start' });
          }}
        >
          國際四格
        </Button>
        <Button
          leftIcon={<RiLayoutGridFill />}
          colorScheme="blue"
          variant="outline"
          size="sm"
          onClick={e => {
            setChannelSet(jpChannels);
            let hero = document.getElementById('videoPlayer');
            e.preventDefault();
            hero && hero.scrollIntoView({ behavior: 'smooth', block: 'start' });
          }}
        >
          日本四格
        </Button>
        <Button
          leftIcon={<RiLayoutGridFill />}
          colorScheme="blue"
          variant="outline"
          size="sm"
          onClick={e => {
            setChannelSet(hkChannels);
            let hero = document.getElementById('videoPlayer');
            e.preventDefault();
            hero && hero.scrollIntoView({ behavior: 'smooth', block: 'start' });
          }}
        >
          香港四格
        </Button>
        <Button
          leftIcon={<RiLayoutGridFill />}
          colorScheme="blue"
          variant="outline"
          size="sm"
          onClick={e => {
            setChannelSet(twChannels);
            let hero = document.getElementById('videoPlayer');
            e.preventDefault();
            hero && hero.scrollIntoView({ behavior: 'smooth', block: 'start' });
          }}
        >
          台灣四格
        </Button>
        <Spacer />
        <IconButton
          colorScheme="purple"
          aria-label="Play All"
          size="sm"
          icon={<RiPlayMiniFill />}
          onClick={() => setVPlaying(true)}
        />
        <IconButton
          colorScheme="red"
          aria-label="Pause All"
          size="sm"
          icon={<RiPauseFill />}
          onClick={() => setVPlaying(false)}
        />
      </Wrap>
      <Box minW="100%" height={{ lg: '100%' }}>
        {/* <GridLayout className="layout" cols={2} width="100vw">
          <Box key="a" data-grid={{ x: 0, y: 0, w: 1, h: 1 }}>
            <VideoPlayer />
          </Box>
        </GridLayout> */}

        <SimpleGrid minChildWidth={{ lg: '50%', base: '100%' }} spacing={0}>
          {Object.entries(channelSet).map(item => {
            //console.log(await CheckDynamicStream(item[1], 1));

            return (
              <Box id="videoPlayer" bg="black">
                <AspectRatio ratio={16 / 9} maxH={{ lg: '50vh' }}>
                  <ReactPlayer
                    url={LiveChannels.tvChannels[item[1]].streamLink[0]}
                    width="100%"
                    height="100%"
                    playing={vPlaying}
                    config={{
                      file: {
                        attributes: {
                          crossorigin: 'anonymous',
                          controls: true,
                          pip: true,
                          autoPlay: true,
                          muted: true,
                          playsInline: true,
                        },
                      },
                      youtube: {
                        playerVars: {
                          rel: 0,
                          controls: 0,
                          autoPlay: 1,
                          mute: 1,
                          disablekb: 1,
                          playsinline: 1,
                        },
                      },
                    }}
                  />
                </AspectRatio>
              </Box>
            );
          })}
        </SimpleGrid>
      </Box>
    </div>
  );
}

export default Multiviewer;
