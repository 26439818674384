import React, { useEffect } from 'react';
import axios from 'axios';
import ReactPlayer from 'react-player';
import {
  SimpleGrid,
  AspectRatio,
  useControllableState,
} from '@chakra-ui/react';

function JapanYT() {
  const corsApi = '';
  const channelID = [
    'UCbTLJi8lXWIt_d1AtRv4Nmw',
    'UCQ2mmGKtrBp6rL8tSMJCCwA',
    'UCynX4LJTQ_H7_KPy7QiIS2A',
    'UC3DrGPmCHsrionkmduqX0qg',
    // 'UCWzx-v_6kdTKi3oXhWOK1FA',
  ];

  const [gridNum, setGridNum] = useControllableState({
    defaultValue: 4,
  });

  useEffect(() => {
    document.title = 'DubDub watchTV | Japan Live with Youtube';

    const timer = setTimeout(() => {
      let hero = document.getElementById('videoWall');
      hero && hero.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }, 2000);
    return () => clearTimeout(timer);
  }, []);

  function checkYoutubeLiveLink(ch) {
    axios
      .get(
        `https://cors.lwp.workers.dev/?https://www.youtube.com/channel/${ch}/live`
      )
      .then(res => {});
  }

  function CalculateGridSize(grid) {
    return 100 / Math.sqrt(gridNum) + '%';
  }

  checkYoutubeLiveLink(channelID);

  return (
    <div className="japanyt">
      <SimpleGrid
        minChildWidth={{ lg: CalculateGridSize(gridNum), base: '100%' }}
        height={{ lg: '100%' }}
        spacing={0}
        id="videoWall"
      >
        {channelID.slice(0, gridNum).map(id => (
          <AspectRatio ratio={16 / 9} w="100%">
            <iframe
              title="youtube"
              src={`https://www.youtube.com/embed/live_stream?channel=${id}&autoplay=1&disablekb=1&mute=1&fs=0&modestbranding=1&rel=0&controls=0`}
              frameborder="0"
            />
          </AspectRatio>
        ))}
      </SimpleGrid>
    </div>
  );
}

export default JapanYT;
