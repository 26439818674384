const common = {
  tc: {
    nowPlaying: '現正播放',
    allChannel: '📡 所有頻道',
    hkChannel: '🇭🇰 香港頻道',
    jpChannel: '🇯🇵 日本頻道',
    moChannel: '🇲🇴 澳門頻道',
    twChannel: '🇹🇼 台灣頻道',
    newsChannel: '📰 新聞頻道',
    sportsChannel: '⛹️‍♂️ 體育頻道',
    kidsChannel: '🧸 兒童頻道',
    disclaimer: '免責聲明：本網頁的來源蒐集至互聯網，用於網絡測試之用。',
  },
  en: {
    nowPlaying: 'Now Playing',
    allChannel: '📡 All Channels',
    hkChannel: '🇭🇰 Hong Kong Channels',
    jpChannel: '🇯🇵 Japan Channels',
    moChannel: '🇲🇴 Macau Channels',
    twChannel: '🇹🇼 Taiwan Channels',
    newsChannel: '📰 News Channels',
    sportsChannel: '⛹️‍♂️ Sports Channels',
    kidsChannel: '🧸 兒童頻道',
    disclaimer:
      'Disclaimer: The sources of this page was collected from the Internet for network testing purposes.',
  },
};

export default { common };
