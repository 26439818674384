import React from 'react';
import {
  ButtonGroup,
  Container,
  IconButton,
  Stack,
  Text,
  Link,
  Code,
} from '@chakra-ui/react';

import Common from '../dictionary/Common';
import appInfo from '../../package.json';

function Footer() {
  return (
    <div className="footer">
      <Container
        as="footer"
        textAlign="left"
        role="contentinfo"
        maxW="100vw"
        py={{ base: '12', md: '16' }}
      >
        <Text fontSize="sm" color="subtle">
          {Common.common.tc.disclaimer}
        </Text>
        <Text fontSize="sm" color="subtle">
          {Common.common.en.disclaimer}
        </Text>
        <Code fontSize="xs" colorScheme="yellow" marginTop={3} p={0.5}>
          Version: {appInfo.version}
        </Code>
        <Text fontSize="sm" color="subtle" paddingTop={5}>
          &copy; {new Date().getFullYear()} DubDub watchTV, Designed by{' '}
          <Link color="teal.500" href="https://dubdub.pro/" isExternal>
            {' '}
            DubDub Production{' '}
          </Link>
          .
        </Text>
      </Container>
    </div>
  );
}

export default Footer;
